const { initializeApp } = require("firebase");
const firebase = require("firebase/app");
require("firebase/messaging");

exports.initializeFirebaseApp = function() {
  const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    projectId: process.env.FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID
  }

  firebase.initializeApp(firebaseConfig);
}
